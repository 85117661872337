<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Soft Hyphen Konverter</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>Konfiguration</h2>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="minWordLength">Minimale Wortlänge</label>
          <input type="number" class="form-control" id="minWordLength" v-model="minWordLength">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h1>EN</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextarea1">Copy</label>
        </div>
        <div class="row">
          <textarea class="form-control" id="exampleFormControlTextarea1" v-model="ENcopy" rows="6"></textarea>
          <button type="button" class="btn btn-primary" @click="convertENcopy()">Convert</button>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextarea2">Generated Copy</label>
        </div>
        <div class="row">
          <textarea v-model="ENcopyConverted" rows="6"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h1>DE</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextareaDE">Copy</label>
        </div>
        <div class="row">
          <textarea class="form-control" id="exampleFormControlTextareaDE" v-model="DEcopy" rows="6"></textarea>
          <button type="button" class="btn btn-primary" @click="convertDEcopy()">Convert</button>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextareaDE2">Generated Copy</label>
        </div>
        <div class="row">
          <textarea v-model="DEcopyConverted" rows="6"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h1>IT</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextareaIT">Copy</label>
        </div>
        <div class="row">
          <textarea class="form-control" id="exampleFormControlTextareaIT" v-model="ITcopy" rows="6"></textarea>
          <button type="button" class="btn btn-primary" @click="convertITcopy()">Convert</button>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextareaIT2">Generated Copy</label>
        </div>
        <div class="row">
          <textarea v-model="ITcopyConverted" rows="6"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h1>FR</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextareaFR">Copy</label>
        </div>
        <div class="row">
          <textarea class="form-control" id="exampleFormControlTextareaFR" v-model="FRcopy" rows="6"></textarea>
          <button type="button" class="btn btn-primary" @click="convertFRcopy()">Convert</button>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <label for="exampleFormControlTextareaFR2">Generated Copy</label>
        </div>
        <div class="row">
          <textarea v-model="FRcopyConverted" rows="6"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hyphenate as hyphenateEN } from "hyphen/en-gb"
import { hyphenate as hyphenateDE } from "hyphen/de-1996"
import { hyphenate as hyphenateIT } from "hyphen/it"
import { hyphenate as hyphenateFR } from "hyphen/fr"

export default {
  name: 'App',
  data() {
    return {
      ENcopy: "It was sev­en o’clock of a very warm evening in the Seeonee hills when Fa­ther Wolf woke up from his day’s rest, scratched him­self, yawned, and spread out his paws one af­ter the oth­er to get rid of the sleepy feel­ing in their tips. Moth­er Wolf lay with her big gray nose dropped across her four tum­bling, squeal­ing cubs, and the moon shone into the mouth of the cave where they all lived. “Au­grh!” said Fa­ther Wolf. “It is time to hunt again.” He was go­ing to spring down hill when a lit­tle shad­ow with a bushy tail crossed the thresh­old and whined: “Good luck go with you, O Chief of the Wolves. And good luck and strong white teeth go with no­ble chil­dren that they may nev­er for­get the hun­gry in this world.",
      ENcopyConverted: "",
      DEcopy: "Denke größer – mit einem Crossover, dessen Design mutig und selbstbewusst ist. Großzügig und gleichzeitig minimalistisch im Innenraum. Und zukunftsweisend mit seinen fortschrittlichen Technologien, der innovativen Konnektivität und seinen Assistenzsystemen. Wähle einfach zwischen dem vollelektrischen Kia Niro EV, dem Kia Niro Plug-in Hybrid und dem Kia Niro Hybrid.",
      DEcopyConverted: "",
      ITcopy: "In Brick To The Future 350.000 mattoncini si aggiungono, incastrano e combinano insieme a comporre le tappe del viaggioche ci accompagna verso una nuova forma dell’ispirazione.",
      ITcopyConverted: "",
      FRcopy: "Découvrez un Crossover familial résolument innovant. Au design extérieur racé et affirmé. À l’intérieur spacieux et sobre. Futuriste par ses technologies, sa connectivité et sa sécurité hautement évoluées. Faites votre choix entre le Niro EV 100 % électrique, le Niro Hybride et le Niro Hybride Rechargeable.",
      FRcopyConverted: "",
      minWordLength: 4
    }
  },
  setup() {
  },
  methods: {
    convertENcopy() {
      hyphenateEN(this.ENcopy, { debug: true, hyphenChar: "&shy;", minWordLength: this.minWordLength }).then(result => {
        this.ENcopyConverted = result
      })
    },
    convertDEcopy() {
      hyphenateDE(this.DEcopy, { debug: true, hyphenChar: "&shy;", minWordLength: this.minWordLength }).then(result => {
        this.DEcopyConverted = result
      })
    },
    convertITcopy() {
      hyphenateIT(this.ITcopy, { debug: true, hyphenChar: "&shy;", minWordLength: this.minWordLength }).then(result => {
        this.ITcopyConverted = result
      })
    },
    convertFRcopy() {
      hyphenateFR(this.FRcopy, { debug: true, hyphenChar: "&shy;", minWordLength: this.minWordLength }).then(result => {
        this.FRcopyConverted = result
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  hyphens: none;
}
</style>
